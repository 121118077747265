import React, { Component } from "react"
import axios from "axios"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"

const API_PATH = "/api-mail-questions.php"
const SignupSchema = Yup.object({
  1: Yup.string().required("Pole wymagane."),
  2: Yup.string().required("Pole wymagane."),
  3: Yup.string()
    .min(5, "Zawartość pola jest zbyt krótka")
    .max(4500, "Zawartość pola jest zbyt długa")
    .required("Pole wymagane"),
  4: Yup.string().required("Pole wymagane."),
  5: Yup.string().required("Pole wymagane."),
  6: Yup.string().required("Pole wymagane."),
  7: Yup.string().required("Pole wymagane."),
  8: Yup.string().required("Pole wymagane."),
  9: Yup.string().required("Pole wymagane."),
  10: Yup.string().required("Pole wymagane."),
})

export default class questionsForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      opened: true,
      name: this.props.name,
      email: this.props.email,
      phone: this.props.phone,
      1: "",
      2: "",
      3: "",
      4: "",
      5: "",
      6: "",
      7: "",
      8: "",
      9: "",
      10: "",
      mailSent: false,
      error: null,
    }
  }

  handleFormSubmit = e => {
    // e.preventDefault();
    axios({
      method: "POST",
      url: `${API_PATH}`,
      headers: { "content-type": "application/json" },
      data: e,
    })
      .then(result => {
        this.setState({
          mailSent: result.data.sent,
          error: result.data.message,
        })
        console.log(result)
      })
      .catch(error => this.setState({ error: error.message }))
  }

  closeModal = e => {
    this.setState({ opened: false })
  }

  render() {
    if (this.state.mailSent === false) {
      return (
        <Formik
          initialValues={{
            name: this.props.name,
            email: this.props.email,
            phone: this.props.phone,
            1: "",
            2: "",
            3: "",
            4: "",
            5: "",
            6: "",
            7: "",
            8: "",
            9: "",
            10: "",
          }}
          validationSchema={SignupSchema}
          onSubmit={values => {
            this.handleFormSubmit(values)
          }}
        >
          {({ isSubmitting }) => (
            <Form
              className={
                this.state.opened
                  ? "questions-form opened"
                  : "questions-form closed"
              }
            >
              <div className="wrap">
                <div className="close-modal" onClick={this.closeModal}>
                  &#10005;
                </div>
                <h4>Dziękujemy za wysłanie wiadomości</h4>
                <h2>Jeśli możesz odpowiedz na kilka dodatkowych pytań</h2>
                <Field
                  name="name"
                  type="hidden"
                  disabled="true"
                  value={this.state.name}
                />
                <Field
                  name="email"
                  type="hidden"
                  disabled="true"
                  value={this.state.email}
                />
                <Field
                  name="phone"
                  type="hidden"
                  disabled="true"
                  value={this.state.phone}
                />

                <ol>
                  <li>
                    <label htmlFor="1">
                      Czy zostałeś skazany, mimo, że nie popełniłeś przestępstwa
                      i posiadasz dowód swojej niewinności?
                    </label>
                    <label>
                      <Field type="radio" as="radio" name="1" value={"Tak"} />
                      Tak
                    </label>
                    <label>
                      <Field type="radio" as="radio" name="1" value={"Nie"} />
                      Nie
                    </label>
                    <ErrorMessage
                      className="error-msg"
                      name="1"
                      component="div"
                    />
                  </li>
                  <li>
                    <label htmlFor="2">
                      Czy zostałeś prawomocnie skazany, a następnie na skutek
                      kasacji, skargi nadzwyczajnej lub wznowienia postępowania
                      uniewinniony, orzeczono karę łagodniejszą lub umorzono
                      postępowanie i chcesz walczyć o odszkodowanie i
                      zadośćuczynienie?
                    </label>
                    <label>
                      <Field type="radio" as="radio" name="2" value={"Tak"} />
                      Tak
                    </label>
                    <label>
                      <Field type="radio" as="radio" name="2" value={"Nie"} />
                      Nie
                    </label>
                    <ErrorMessage
                      className="error-msg"
                      name="2"
                      component="div"
                    />
                  </li>
                  <li>
                    <label htmlFor="3">
                      Czy przebywałeś w areszcie śledczym lub zakładzie karnym?
                      Jakie nieodpowiednie warunki w nim panowały?
                    </label>
                    <Field
                      as="textarea"
                      type="textarea"
                      component="textarea"
                      name="3"
                      rows="8"
                    />
                    <ErrorMessage
                      className="error-msg"
                      name="3"
                      component="div"
                    />
                  </li>
                  <li>
                    <label htmlFor="4">
                      Czy zostałeś uniewinniony, a na skutek prowadzenia
                      postępowania karnego doznałeś szkody i chcesz dochodzić
                      odszkodowania?
                    </label>
                    <label>
                      <Field type="radio" as="radio" name="4" value={"Tak"} />
                      Tak
                    </label>
                    <label>
                      <Field type="radio" as="radio" name="4" value={"Nie"} />
                      Nie
                    </label>
                    <ErrorMessage
                      className="error-msg"
                      name="4"
                      component="div"
                    />
                  </li>
                  <li>
                    <label htmlFor="5">
                      Czy zostałeś zatrzymany, a następnie zwolniony bez
                      postawienia zarzutów?
                    </label>
                    <label>
                      <Field type="radio" as="radio" name="5" value={"Tak"} />
                      Tak
                    </label>
                    <label>
                      <Field type="radio" as="radio" name="5" value={"Nie"} />
                      Nie
                    </label>
                    <ErrorMessage
                      className="error-msg"
                      name="5"
                      component="div"
                    />
                  </li>
                  <li>
                    <label htmlFor="6">
                      Czy zostałeś tymczasowo aresztowany w toku postępowania, a
                      następnie uniewinniony?
                    </label>
                    <label>
                      <Field type="radio" as="radio" name="6" value={"Tak"} />
                      Tak
                    </label>
                    <label>
                      <Field type="radio" as="radio" name="6" value={"Nie"} />
                      Nie
                    </label>
                    <ErrorMessage
                      className="error-msg"
                      name="6"
                      component="div"
                    />
                  </li>
                  <li>
                    <label htmlFor="7">
                      Czy uważasz, że wobec Ciebie niesłusznie orzeczono i
                      wykonywano środek karny, który następnie uchylono?
                    </label>
                    <label>
                      <Field type="radio" as="radio" name="7" value={"Tak"} />
                      Tak
                    </label>
                    <label>
                      <Field type="radio" as="radio" name="7" value={"Nie"} />
                      Nie
                    </label>
                    <ErrorMessage
                      className="error-msg"
                      name="7"
                      component="div"
                    />
                  </li>
                  <li>
                    <label htmlFor="8">
                      Czy zostałeś niesłusznie oskarżony, toczyło się przeciwko
                      Tobie postępowanie karne, ale finalnie wydano wyrok
                      uniewinniający i czujesz się poszkodowany?
                    </label>
                    <label>
                      <Field type="radio" as="radio" name="8" value={"Tak"} />
                      Tak
                    </label>
                    <label>
                      <Field type="radio" as="radio" name="8" value={"Nie"} />
                      Nie
                    </label>
                    <ErrorMessage
                      className="error-msg"
                      name="8"
                      component="div"
                    />
                  </li>
                  <li>
                    <label htmlFor="9">
                      Czy doznałeś krzywdy lub szkody na skutek niesłusznego
                      skazania osoby najbliższej i chcesz dochodzić
                      zadośćuczynienia lub naprawienia szkody?
                    </label>
                    <label>
                      <Field type="radio" as="radio" name="9" value={"Tak"} />
                      Tak
                    </label>
                    <label>
                      <Field type="radio" as="radio" name="9" value={"Nie"} />
                      Nie
                    </label>
                    <ErrorMessage
                      className="error-msg"
                      name="9"
                      component="div"
                    />
                  </li>
                  <li>
                    <label htmlFor="10">
                      Czy doznałeś szkody wskutek bezprawnego działania władzy
                      państwowej i chcesz odszkodowania?
                    </label>
                    <label>
                      <Field type="radio" as="radio" name="10" value={"Tak"} />
                      Tak
                    </label>
                    <label>
                      <Field type="radio" as="radio" name="10" value={"Nie"} />
                      Nie
                    </label>
                    <ErrorMessage
                      className="error-msg"
                      name="10"
                      component="div"
                    />
                  </li>
                </ol>
                <button
                  className="submit"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Wyślij
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )
    } else {
      return (
        <form
          className={
            this.state.opened
              ? "questions-form opened message-sent"
              : "questions-form closed message-sent"
          }
        >
          <div className="wrap">
            <div className="close-modal" onClick={this.closeModal}>
              &#10005;
            </div>
            <h3>Dziękujemy!</h3>
            <p>Twoje odpowiedzi zostały wysłane.</p>
            <p>
              Postaramy się podjąć próbę kontaktu z Państwem za pośrednictwem
              podanych w formularzu danych. W razie braku odpowiedzi z naszej
              strony jest możliwość rozmowy telefonicznej +48 535 850 748 lub
              napisania maila na kontakt@jestemniewinny.pl
            </p>
            <p>
              <strong>
                {" "}
                <a href="tel:+48535850748">+48 535 850 748</a>
              </strong>
              <br />
              <strong>
                <a href="mailto:kontakt@jestemniewinny.pl">
                  kontakt@jestemniewinny.pl
                </a>
              </strong>
            </p>
          </div>
        </form>
      )
    }
  }
}
