import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/contactForm"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"

const IndexPage = () => (
  <Layout>
    <SEO title="Jestem Niewinny" />
    <section className="top" id="top">
      <div className="wrap">
        <div className="column left-column">
          <Slider autoplay arrows={0} fade={1}>
            <div>
              <h4>Niesłusznie skazany</h4>
              <h2>
                Zostałeś
                <br /> <span>niesłusznie</span>
                <br /> skazany?
              </h2>
            </div>
            <div>
              <h4>Niewinny w areszcie</h4>
              <h2>
                Zamknięto Cię w areszcie za <span>coś czego nie zrobiłeś?</span>
              </h2>
            </div>
            <div>
              <h4>Potrzeba sprawiedliwości</h4>
              <h2>
                Chcesz
                <br />
                dochodzić
                <br /> <span>sprawiedliwości?</span>
              </h2>
            </div>
            <div>
              <h4>Należysz do rodziny osoby skazanej?</h4>{" "}
              <h2>
                Uzyskaj <span>odszkodowanie</span>
              </h2>
            </div>
            <div>
              <h4>Możemy Ci pomóc!</h4>
              <h2>
                Należy <br />
                Ci się
                <br /> <span>odszkodowanie!</span>
              </h2>
            </div>
          </Slider>
          <div className="top-cta">
            <a className="cta-link" href="#formularz">
              Przejdź do formularza &rarr;
            </a>
          </div>
        </div>
        <div className="column right-column">
          <div className="img-bg">
            <img src={`/static/nieslusznie-skazany.jpg`} alt="" />
          </div>
        </div>
      </div>
    </section>
    <section className="why-us-section" id="formularz">
      <div className="wrap">
        <div className="why-us ">
          <h4>Nasze cele</h4>
          <h2>O kancelarii:</h2>
          <ol>
            <li>
              Pomagamy osobom, które doświadczyły niesprawiedliwości. Zostały
              niesłusznie skazane bądź zatrzymane.
            </li>
            <li>
              Dochodzenie odszkodowania służyć ma naprawieniu poniesionej
              szkody. Zadośćuczynić za poniesioną krzywdę.
            </li>
            <li>
              Zadośćuczynienie nie ma jasnego przelicznika, więc kwoty
              odszkodowań zaczynają się od kilku tysięcy do nawet
              kilkudziesięciu milionów.
            </li>
            <li>
              Jako doświadczony zespół Prawników gwarantujemy, że dojdziemy
              sprawiedliwości w Sądzie!
            </li>
          </ol>
        </div>
        <ContactForm />
      </div>
    </section>
    <section className="reasons-why">
      <div className="wrap">
        <h2>Historie Klientów:</h2>

        <div className="reason-why">
          Nigdy nie przypuszczałem, że znajdę się w takiej sytuacji. Zatrzymano
          mnie na ulicy, w środku miasta, zaciągnięto na komisariat i
          przedstawiono zarzut brutalnego pobicia ... odsiedziałem kilka tygodni
          zanim okazało się, że w końcu uwierzono, że to nie ja dopuściłem się
          tego pobicia. Po prostu byłem bardzo podobny do sprawcy. A co za to
          dostałem ? NIC! Straciłem pracę, musiałem odzyskać zaufanie rodziny.
          Kancelaria pomogła mi dochodzić odszkodowania, za te pieniądze mogłem
          zacząć od nowa.
        </div>
        <div className="reason-why">
          Nikt nie jest w stanie zrozumieć co naprawdę dzieje się w więzieniu
          dopóki go tam nie zamkną. Mnie zamknięto za udział w demonstracji, a
          tak na prawdę za to, że stanąłem w obronie ciężarnej kobiety. W
          areszcie spędziłem kilka dni, bardzo odbiło się to na mojej psychice.
          Całe szczęście otrzymałem zadośćuczynienie, Kancelaria zadbała o to,
          abym nie był poszkodowany w tej całej sytuacji.
        </div>

        <div className="reason-why">
          Każdy myśli, że kilka lat spędzony w więzieniu za coś czego się nie
          zrobiło jest niemożliwe...że więzienie jest pełne niewinnych, ale ja
          jestem dowodem na to, że to prawda. Po 7 latach odsiadki postanowiłem
          nie chować głowy w piasek. Kancelaria udowodniła, że mam rację - teraz
          mogę żyć na nowo.
        </div>
        <div className="reason-why">
          Nie dajcie im wywinąć się z tego co zrobili! Każdy ma prawo dochodzić
          sprawiedliwości. Ja przez zamknięcie mnie za coś czego nie zrobiłem
          straciłem partnerkę, odwróciła się ode mnie rodzina, przyjaciele...
          walczcie, polecam Wam tych prawników, wiedzą co robią.
        </div>
      </div>
      <div className="reasons-why-cta">
        <a className="cta-link" href="#formularz">
          Przejdź do formularza &rarr;
        </a>
      </div>
    </section>
    <section className="team">
      <div className="wrap">
        <h2>Nasz zespół</h2>
        <div className="team-member">
          <div className="team-member-description">
            <h3>Beata Gulczyńska</h3>
            <p>
              Ma wieloletnie doświadczenie w dochodzeniu odszkodowań. Dzięki
              ogromnemu zaangażowaniu oraz empatii dla osób borykających się z
              trudnymi doświadczeniami są kluczem do osiągnięcia sukcesu. Zawsze
              stawia interes klienta na pierwszym miejscu.
            </p>
          </div>
          <img src={`/static/beata-gulczynska.jpg`} alt="Beata Gulczyńska" />
        </div>
        <div className="team-member">
          <img src={`/static/jpapiez.jpg`} alt="Jonarz Papież" />
          <div className="team-member-description">
            <h3>Jonasz Papież</h3>
            {/* <h4>Dyrektor Pionu Organizacyjnego</h4> */}
            <p>
              Nieustannie poszukuje nowych technik gwarantujących zapewnienie
              wysokiej jakości obsługi klientów kancelarii. Współtworzy projekt
              Jestem Niewinny oraz zarządza Fundacją Wspólna Sprawa, której
              celem jest świadczenie bezpłatnej pomocy osobom niegdyś
              represjonowanym w czasach komunistycznych. Fascynuje się historią
              powojenną.
            </p>
          </div>
        </div>
      </div>
      <div className="reasons-why-cta">
        <a className="cta-link" href="#formularz">
          Przejdź do formularza &rarr;
        </a>
      </div>
    </section>
    <section className="map">
      <div className="wrap">
        <h2>Spotkajmy się w siedzibie kancelarii:</h2>
        <div>
          Gdynia ul. 10 lutego 16,
          <br />
          Centrum Kwiatkowskiego, Wejście A II piętro
        </div>
        <a
          target="blank"
          href="https://goo.gl/maps/WhjmRHVyx6abkP1w5"
          className="button"
        >
          Otwórz mapę w nowym oknie
        </a>
      </div>
    </section>
  </Layout>
)

export default IndexPage
