import React, { Component } from "react"
import axios from "axios"
import { Formik, Form, Field, ErrorMessage } from "formik"
import QuestionsForm from "./questionsForm.jsx"
import * as Yup from "yup"

const API_PATH = "/api-mail.php"
const SignupSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Zawartość pola jest zbyt krótka")
    .max(50, "Zawartość pola jest zbyt długa")
    .required("Pole wymagane"),
  email: Yup.string()
    .min(2, "Zawartość pola jest zbyt krótka")
    .max(50, "Zawartość pola jest zbyt długa")
    .email("Nieprawidłowy format adresu e-mail")
    .required("Pole wymagane"),
  phone: Yup.string()
    .min(5, "Zawartość pola jest zbyt krótka")
    .max(15, "Zawartość pola jest zbyt długa")
    .required("Pole wymagane"),
  privacy: Yup.bool().oneOf([true], "Wymagana zgoda na przetwarzanie danych."),
  message: Yup.string()
    .min(5, "Zawartość pola jest zbyt krótka")
    .max(1500, "Zawartość pola jest zbyt długa")
    .required("Pole wymagane"),
})

export default class contactForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      email: "",
      phone: "",
      message: "",
      mailSent: false,
      error: null,
    }
  }
  handleFormSubmit = e => {
    // e.preventDefault();
    axios({
      method: "POST",
      url: `${API_PATH}`,
      headers: { "content-type": "application/json" },
      data: e,
    })
      .then(result => {
        this.setState({
          mailSent: result.data.sent,
          error: result.data.message,
          name: e.name,
          email: e.email,
          phone: e.phone,
        })
        // console.log(e)
        fbq("track", "Lead")
      })
      .catch(error => this.setState({ error: error.message }))
  }

  render() {
    if (this.state.mailSent === false) {
      return (
        <Formik
          initialValues={{
            name: "",
            email: "",
            phone: "",
            message: "",
            privacy: false,
          }}
          validationSchema={SignupSchema}
          onSubmit={values => {
            this.handleFormSubmit(values)
          }}
        >
          {({ isSubmitting }) => (
            <Form className="lead-form">
              <h4>Napisz do nas</h4>
              <h2>Zapraszamy do kontaktu</h2>
              {/* <label htmlFor="name">Imię i nazwisko:</label> */}
              <Field
                type="text"
                name="name"
                placeholder="Twoje imię i nazwisko"
              />
              <ErrorMessage className="error-msg" name="name" component="div" />
              {/* <label htmlFor="email">Adres e-mail:</label> */}
              <Field type="email" name="email" placeholder="Twój e-mail" />
              <ErrorMessage
                className="error-msg"
                name="email"
                component="div"
              />
              {/* <label htmlFor="phone">Numer telefonu:</label> */}
              <Field
                type="phone"
                name="phone"
                placeholder="Twój numer telefonu"
              />
              <ErrorMessage
                className="error-msg"
                name="phone"
                component="div"
              />
              <Field
                as="textarea"
                type="textarea"
                component="textarea"
                name="message"
                placeholder="Twoja wiadomość"
                rows="4"
              />
              <ErrorMessage
                className="error-msg"
                name="message"
                component="div"
              />
              <button className="submit" type="submit" disabled={isSubmitting}>
                Wyślij
              </button>
              <ErrorMessage
                className="error-msg"
                name="privacy"
                component="div"
              />
              <Field
                className="checkbox"
                type="checkbox"
                id="privacy"
                name="privacy"
              />
              <label className="privacy" htmlFor="privacy">
                Zgodnie z zasadami{" "}
                <a target="blank" href="/regulamin/">
                  Regulaminu i Polityki Prywatności
                </a>{" "}
                zgadzam się na przetwarzanie moich danych osobowych przez
                Administratora - Business Trade Sp. z o. o., także zbieranych i
                przechowywanych w plikach „cookies”, pozostawionych podczas
                korzystania przeze mnie z usług Business Trade w tym ze stron
                internetowych, serwisów i innych funkcjonalności, w celach
                marketingowych (również analizowanie i profilowanie w celach
                marketingowych). Ponadto wyrażam zgodę na powierzenie
                przetwarzania moich danych osobowych{" "}
                <a target="blank" href="/regulamin/">
                  Podmiotom Grupy Kapitałowej i Zaufanym Partnerom.
                </a>
              </label>
            </Form>
          )}
        </Formik>
      )
    } else {
      return (
        <form className="lead-form message-sent">
          <QuestionsForm
            name={this.state.name}
            email={this.state.email}
            phone={this.state.phone}
          />
          <h3>Dziękujemy!</h3>
          <p>Wiadomość została wysłana.</p>
          <p>
            Postaramy się podjąć próbę kontaktu z Państwem za pośrednictwem
            podanych w formularzu danych. W razie braku odpowiedzi z naszej
            strony jest możliwość rozmowy telefonicznej 535 850 748 lub
            napisania maila na kontakt@jestemniewinny.pl
          </p>
          <p>
            <strong>
              {" "}
              <a href="tel:+48535850748">+48 535 850 748</a>
            </strong>
            <br />
            <strong>
              <a href="mailto:kontakt@jestemniewinny.pl">
                kontakt@jestemniewinny.pl
              </a>
            </strong>
          </p>
        </form>
      )
    }
  }
}
